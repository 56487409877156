/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-06-01 15:58:08
 * @LastEditors: Json Weng dinglong.weng@travel-easy.com
 * @LastEditTime: 2024-06-15 16:12:56
 * @FilePath: /settlement-frontend/src/core/api/apiIntegration.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getDatevReportList(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/document/datev-report/list",
      params
    );
  },
  getDatevReportOption(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/document/datev-report/option",
      params
    );
  },
  getDatevReportYearOption(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/document/datev-report/year-option",
      params
    );
  },
  exportDatevReport(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/document/datev-report/download",
      params,
      { responseType: "blob" }
    );
  },
  updateDatevReportStatus(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/document/datev-report/change-status",
      params
    );
  },
};
