/*
 * @Author: xiang.gao 57524479+GaoXiang0403@users.noreply.github.com
 * @Date: 2024-04-25 10:28:37
 * @LastEditors: xiang.gao 57524479+GaoXiang0403@users.noreply.github.com
 * @LastEditTime: 2024-04-30 15:52:15
 * @FilePath: /settlement-frontend/src/core/api/entity-relationships.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  // entity-relation
  getEntityRelationAgentAccounts(params, config?) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/entity-relation/agent-accounts",
      params,
      {
        ...config,
      }
    );
  },
  // Supplier
  getSupplierList(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/entity-relation-view/ssp/supplier/list",
      params
    );
  },
  exportSupplier(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/entity-relation-view/ssp/supplier/export",
      params,
      { responseType: "blob" }
    );
  },
  // Buyer
  getBuyerList(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/entity-relation-view/ssp/product-buyer/list",
      params
    );
  },
  exportBuyer(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/entity-relation-view/ssp/product-buyer/export",
      params,
      { responseType: "blob" }
    );
  },
  // Clientele
  getClienteleList(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/entity-relation-view/ssp/clientele/list",
      params
    );
  },
  exportClientele(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/entity-relation-view/ssp/clientele/export",
      params,
      { responseType: "blob" }
    );
  },
  // Payee
  getPayeeList(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/entity-relation-view/ssp/payee/list",
      params
    );
  },
  exportPayee(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/entity-relation-view/ssp/payee/export",
      params,
      { responseType: "blob" }
    );
  },
  // Rebate
  getRebateList(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/entity-relation-view/ssp/rebate/list",
      params
    );
  },
  exportRebate(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/entity-relation-view/ssp/rebate/export",
      params,
      { responseType: "blob" }
    );
  },
  // Exporter
  getExporterList(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/entity-relation-view/retailer/exports/list",
      params
    );
  },
  exportExporter(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/entity-relation-view/retailer/exports/export",
      params,
      { responseType: "blob" }
    );
  },
  // Tax Agent
  getTaxAgentList(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/entity-relation-view/retailer/tax-agent/list",
      params
    );
  },
  exportTaxAgent(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/entity-relation-view/retailer/tax-agent/export",
      params,
      { responseType: "blob" }
    );
  },
  //
  // Retailer Buyer
  getRetailerBuyerList(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/entity-relation-view/retailer/buyer/list",
      params
    );
  },
  exportRetailerBuyer(params) {
    return ApiService.post(
      Config.settlementApiUrl +
        "/api/entity-relation-view/retailer/buyer/export",
      params,
      { responseType: "blob" }
    );
  },
};
