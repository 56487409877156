/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-11-04 15:27:17
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-07-26 15:17:49
 */
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { CommonModuleRouter } from "../interface/common";
import { CommonLanguageType } from "../type/common";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getTransactionReportModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setTransactionReportDetailName(language, route, result);
  return result;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
const setTransactionReportDetailName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "";
  result.parentName = [language("common.transactionReport")];
  if (route.path.includes("finacial-report")) {
    result.parentName.push(language("finacialReport.finacialReport"));
    if (route.path.includes("ocps-ss-sales-receivables")) {
      strName = language("finacialReport.ocpsSsSalesReceivables");
      listPath = route.path;
    } else if (route.path.includes("ocps-deposit-management")) {
      strName = language("finacialReport.ocpsDepositManagement");
      listPath = route.path;
    } else if (route.path.includes("ocps-e-commerce-tax-payables")) {
      strName = language("finacialReport.ocpsECommerceTaxPayables");
      listPath = route.path;
    } else if (route.path.includes("ocps-ds-rebates")) {
      strName = language("finacialReport.ocpsDsRebates");
      listPath = route.path;
    } else if (route.path.includes("ocps-vat-receivables")) {
      strName = language("finacialReport.ocpsVatReceivables");
      listPath = route.path;
    } else if (route.path.includes("ocps-merchant-purchasing-payables")) {
      strName = language("finacialReport.ocpsMerchantPurchasingPayables");
      listPath = route.path;
    }
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getTransactionReportDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "documents-inspection-report-information":
      result.currentName = language("common.information");
      setTransactionReportDetailName(language, route, result, router);
      break;
  }
  return result;
};
