/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-05-16 09:39:29
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-09-26 14:42:14
 * @FilePath: /settlement-frontend/src/directives/permission.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Directive } from "vue";
import store from "@/store";

const checkPermission = (el, binding) => {
  const { value } = binding;
  if (value && value.auth instanceof Array) {
    const hasPermission = checkPermissionFlag(value);
    if (!hasPermission) {
      el && el.parentNode && el.parentNode.removeChild(el);
    }
  } else {
    throw new Error(
      "Need permission! Like v-auth={auth: ['view','add'], mode: 'or'}"
    );
  }
};

export const checkPermissionFlag = (binding, flag?) => {
  let hasPermission = false;
  const { mode, auth } = binding;
  const actionPermits = store.getters.routeActionPermits;
  // console.log(actionPermits);
  if (mode == "or") {
    hasPermission = auth.some((item) => {
      return actionPermits.includes(item);
    });
  } else if (mode == "and") {
    hasPermission = auth.every((item) => {
      return actionPermits.includes(item);
    });
  } else {
    hasPermission = auth.some((item) => {
      return actionPermits.includes(item);
    });
  }
  if (flag !== undefined) {
    // console.log("Has permission: " + hasPermission && flag);
    return hasPermission && flag;
  }
  // console.log("Has permission: " + hasPermission);
  return hasPermission;
};

export const auth: Directive = {
  mounted(el, binding) {
    checkPermission(el, binding);
  },
  updated(el, binding) {
    checkPermission(el, binding);
  },
};
