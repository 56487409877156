/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-06-01 14:58:31
 * @LastEditors: Json Weng dinglong.weng@travel-easy.com
 * @LastEditTime: 2024-06-15 22:28:01
 * @FilePath: /settlement-frontend/src/core/directive/function/apiIntegration.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { CommonModuleRouter } from "../interface/common";
import { CommonLanguageType } from "../type/common";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getApiIntegrationModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setApiIntegrationDetailName(language, route, result);
  return result;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
const setApiIntegrationDetailName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "/api-integration";
  result.parentName = [language("common.apiIntegration")];
  if (route.path.indexOf("datev-balance") > -1) {
    strName = language("datevBalance.datevBalance");
    listPath += "datev-balance";
  }
  if (route.path.indexOf("datev-balance-verification") > -1) {
    strName = language("datevBalance.datevBalanceVerification");
    listPath += "datev-balance-verification";
  }
  if (route.path.indexOf("datev-cashflow") > -1) {
    strName = language("datevCashflow.datevCashflow");
    listPath += "datev-cashflow";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getApiIntegrationDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "quick-settlement-information":
      result.currentName = language("common.information");
      setApiIntegrationDetailName(language, route, result, router);
      break;
  }
  return result;
};
