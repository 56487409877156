/*
 * @Author: xiang.gao 57524479+GaoXiang0403@users.noreply.github.com
 * @Date: 2024-05-21 13:12:57
 * @LastEditors: Json Weng dinglong.weng@travel-easy.com
 * @LastEditTime: 2024-06-02 15:35:43
 * @FilePath: /settlement-frontend/src/core/directive/function/kfcVerification.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { RouteLocationNormalizedLoaded, Router, useRoute } from "vue-router";
import { BadgeStyle, CommonLanguageType, NumberOrString } from "../type/common";
import { CommonModuleRouter } from "../interface/common";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { KycVerificationConfig } from "@/core/directive/interface/kycVerification";
import { KycVerificationStatus } from "../type/kycVerification";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getKycVerificationModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setKycVerificationDetailName(language, route, result);
  return result;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
const setKycVerificationDetailName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "/kyc-verification/";
  result.parentName = [language("common.kycVerification")];
  if (route.path.includes("legal-entity")) {
    strName = language("kycVerification.legalEntity.title");
    listPath += "legal-entity";
  } else if (route.path.includes("natural-person")) {
    strName = language("kycVerification.naturalPerson.title");
    listPath += "natural-person";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getKycVerificationDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "kyc-verification-legal-entity-information":
    case "kyc-verification-legal-entity-history-logs":
      result.currentName = language(
        "kycVerification.legalEntity.overviewTitle"
      );
      setKycVerificationDetailName(language, route, result, router);
      break;
    case "kyc-verification-natural-person-information":
    case "kyc-verification-natural-person-history-logs":
      result.currentName = language(
        "kycVerification.naturalPerson.overviewTitle"
      );
      setKycVerificationDetailName(language, route, result, router);
      break;
    case "kyc-verification-legal-entity-add":
      result.currentName = language("kycVerification.legalEntity.addTitle");
      setKycVerificationDetailName(language, route, result, router);
      break;
    case "kyc-verification-natural-person-add":
      result.currentName = language("kycVerification.naturalPerson.addTitle");
      setKycVerificationDetailName(language, route, result, router);
      break;
  }
  return result;
};

/**
 * @description: KYC验证状态badge
 * @param {NumberOrString} status
 * @param {CommonLanguageType} language
 * @return {*}
 */
export const getKycVerificationStatus = (
  status: NumberOrString,
  language: CommonLanguageType
) => {
  let color = "",
    title = "";
  const currentStatus = Number(status) as KycVerificationStatus;
  switch (currentStatus) {
    case KycVerificationStatus.PendingVerification:
      color = BadgeStyle.LightPrimary;
      title = language("kycVerification.status.pendingVerification");
      break;
    case KycVerificationStatus.UnderVerification:
      color = BadgeStyle.LightDanger;
      title = language("kycVerification.status.underVerification");
      break;
    case KycVerificationStatus.PartialVerified:
      color = BadgeStyle.Light;
      title = language("kycVerification.status.partialVerified");
      break;
    case KycVerificationStatus.Confirming:
      color = BadgeStyle.LightWarning;
      title = language("kycVerification.status.confirming");
      break;
    case KycVerificationStatus.Verified:
      color = BadgeStyle.LightSuccess;
      title = language("kycVerification.status.verified");
      break;
    case KycVerificationStatus.VerificationFailed:
      color = BadgeStyle.LightDanger;
      title = language("kycVerification.status.verificationFailed");
      break;
    case KycVerificationStatus.Temporarily:
      color = BadgeStyle.Light;
      title = language("kycVerification.status.temporarily");
      break;
    case KycVerificationStatus.Closed:
      color = BadgeStyle.Light;
      title = language("kycVerification.status.closed");
      break;
    default:
      // eslint-disable-next-line no-case-declarations
      const n: never = currentStatus;
      console.log(n);
      break;
  }
  return {
    color,
    title,
  };
};

export const getKycVerificationTypeParams = computed(() => {
  const route = useRoute();
  //const { t } = useI18n();
  const routeName = route.name;
  const params: KycVerificationConfig = {
    entityType: "",
    routerUrl: "",
  };
  switch (routeName) {
    case "kyc-verification-legal-entity":
    case "kyc-verification-legal-entity-add":
    case "kyc-verification-legal-entity-information":
    case "kyc-verification-legal-entity-history-logs":
      params.entityType = "juridical";
      params.routerUrl = "/kyc-verification/legal-entity";
      break;
    case "kyc-verification-natural-person":
    case "kyc-verification-natural-person-add":
    case "kyc-verification-natural-person-information":
    case "kyc-verification-natural-person-history-logs":
      params.entityType = "natural";
      params.routerUrl = "/kyc-verification/natural-person";
      break;
  }
  return params;
});

export const findDuplicateValues = (arr, key) => {
  const valueCount = new Map();
  const duplicates = new Set();

  arr.forEach((obj) => {
    const keyValue = obj[key];
    if (valueCount.has(keyValue)) {
      valueCount.set(keyValue, valueCount.get(keyValue) + 1);
      duplicates.add(keyValue);
    } else {
      valueCount.set(keyValue, 1);
    }
  });
  return Array.from(duplicates);
};
