/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-06-12 09:08:19
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-07-13 09:28:02
 * @FilePath: /settlement-frontend/src/core/api/finacialReport.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  // DM Report
  getDmReportList(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/transaction/dm-report/index",
      params
    );
  },
  exportDmReport(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/transaction/dm-report/export",
      params,
      { responseType: "blob" }
    );
  },
  // ETP Report
  getEtpReportList(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/transaction/etp-report/index",
      params
    );
  },
  exportEtpReport(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/transaction/etp-report/export",
      params,
      { responseType: "blob" }
    );
  },
  // SSR Report
  getSsrReportList(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/transaction/ssr-report/index",
      params
    );
  },
  exportSsrReport(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/transaction/ssr-report/export",
      params,
      { responseType: "blob" }
    );
  },
  // SSR Add Report
  getSsrAddReportList(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/transaction/ssr-add-report/index",
      params
    );
  },
  exportSsrAddReport(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/transaction/ssr-add-report/export",
      params,
      { responseType: "blob" }
    );
  },
  // DS Rebates
  getDsrReportList(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/transaction/dsr-report/index",
      params
    );
  },
  exportDsrReport(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/transaction/dsr-report/export",
      params,
      { responseType: "blob" }
    );
  },
  // Vat Report
  getVatReportList(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/transaction/etp-report/vat-report-list",
      params
    );
  },
  exportVatReport(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/transaction/etp-report/vat-report-export",
      params,
      { responseType: "blob" }
    );
  },
};
