/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-08-08 16:42:54
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-07-12 16:46:05
 * @FilePath: /settlement-frontend/src/core/language/de/index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import menu from "magicbean-saas-common/src/core/language/de/menu.json";
import common from "./common.json";
import documents from "./documents.json";
import quickSettlement from "./quickSettlement.json";
import orderRequest from "./orderRequest.json";
import order from "./order.json";
import invoice from "./invoice.json";
import creditNotes from "./creditNotes.json";
import paymentConfirmation from "./paymentConfirmation.json";
import datevBalance from "./datevBalance.json";
import merchantCreditNotes from "./merchantCreditNotes.json";
import finacialReport from "./finacialReport.json";
import settlementCash from "./settlementCash.json";
import settlementEntity from "./settlementEntity.json";
import documentCenter from "./documentCenter.json";
import entityRelationships from "./entityRelationships.json";
import entity from "./entity.json";
import serviceManagement from "./serviceManagement.json";
import kycVerification from "./kycVerification.json";
import kycConfirmation from "./kycConfirmation.json";
import refundProcessing from "./refundProcessing.json";

export default {
  menu,
  common,
  documents,
  quickSettlement,
  orderRequest,
  order,
  invoice,
  creditNotes,
  paymentConfirmation,
  datevBalance,
  merchantCreditNotes,
  finacialReport,
  settlementCash,
  settlementEntity,
  documentCenter,
  entityRelationships,
  entity,
  serviceManagement,
  kycVerification,
  kycConfirmation,
  refundProcessing,
};
