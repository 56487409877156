/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-06-07 10:13:19
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-06-13 15:08:16
 * @FilePath: /settlement-frontend/src/core/directive/function/merchantCreditNotes.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { CommonModuleRouter } from "../interface/common";
import { CommonLanguageType } from "../type/common";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getMerchantCreditNotesModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setMerchantCreditNotesDetailName(language, route, result);
  return result;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
const setMerchantCreditNotesDetailName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "/merchant-credit-notes/";
  result.parentName = [];
  if (route.path.indexOf("merchant-credit-notes-finance") > -1) {
    strName = language("common.merchantCreditNotesFinance");
    listPath += "merchant-credit-notes";
  }
  else if (route.path.indexOf("merchant-credit-notes") > -1) {
    strName = language("common.merchantCreditNotes");
    listPath += "merchant-credit-notes";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getMerchantCreditNotesDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "documents-inspection-report-information":
      result.currentName = language("common.information");
      setMerchantCreditNotesDetailName(language, route, result, router);
      break;
  }
  return result;
};


export const getDataVersion = (route: RouteLocationNormalizedLoaded) => {
  if( route.path.indexOf("-finance") > -1 ){
    return 1;
  } else {
    return 2;
  }
}
