/*
 * @Author: Json Weng dinglong.weng@travel-easy.com
 * @Date: 2024-06-04 16:58:23
 * @LastEditors: Json Weng dinglong.weng@travel-easy.com
 * @LastEditTime: 2024-06-06 10:41:58
 * @FilePath: \magicbean-saas-settlement\src\core\directive\type\kycConfirmation.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export enum KycConfirmationStatus {
  PendingApprove = 15,
  Approved = 20,
  Disapproved = 30,
}

export enum KycConfirmationBlockStatus {
  Pending = "pending",
  Approved = "approved",
  Rejected = "rejected",
}

export enum workflowStatus {
  pending = "pending",
  approve = "approve",
  rejected = "rejected",
}
