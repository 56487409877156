/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-07-21 10:54:52
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-09-26 19:24:49
 * @FilePath: /settlement-frontend/src/core/api/settlementEntity.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getList(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/set-settlement-entity/list",
      params
    );
  },
  getShow(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/set-settlement-entity/show",
      params
    );
  },
  store(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/set-settlement-entity/store",
      params
    );
  },
  update(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/set-settlement-entity/update",
      params
    );
  },
  delete(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/set-settlement-entity/delete",
      params
    );
  },
  option(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/set-settlement-entity/option",
      params
    );
  },
  cityOption(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/set-settlement-entity/city-option",
      params
    );
  },
};
