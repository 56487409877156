/*
 * @Author: Json Weng dinglong.weng@travel-easy.com
 * @Date: 2024-06-28 16:42:55
 * @LastEditors: Json Weng dinglong.weng@travel-easy.com
 * @LastEditTime: 2024-07-04 10:32:09
 * @FilePath: \magicbean-saas-settlement\src\core\api\refundProcessing.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getRefundOrderList(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/refund-order/index",
      params
    );
  },

  getRefundOrderDetail(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/refund-order/detail",
      params
    );
  },

  cancelRefund(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/refund-order/cancel",
      params
    );
  },

  confirmRefund(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/refund-order/confirm",
      params
    );
  },

  executeRefund(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/refund-order/exe",
      params
    );
  },

  manualRefund(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/refund-order/manual",
      params
    );
  },

  verifyPassword(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/authorize/verify-password",
      params
    );
  },
};
