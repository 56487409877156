/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-05-16 17:58:45
 * @LastEditors: Json Weng dinglong.weng@travel-easy.com
 * @LastEditTime: 2024-06-15 16:12:11
 * @FilePath: /settlement-frontend/src/core/api/documentCenter.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  // data-source
  getSourceDocumentType(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/data-source/document/type",
      params
    );
  },
  getDocumentDocType(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/document/doc_type/options",
      params
    );
  },
  // Billing
  getBillingList(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/document/billing/list",
      params
    );
  },
  exportBilling(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/document/billing/download",
      params,
      { responseType: "blob" }
    );
  },
  previewBilling(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/document/billing/show-pdf",
      params
    );
  },
  // Payment
  getPaymentList(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/document/payment/list",
      params
    );
  },
  exportPayment(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/document/payment/download",
      params,
      { responseType: "blob" }
    );
  },
  previewPayment(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/document/payment/show-pdf",
      params
    );
  },
  // Invoice
  getInvoiceList(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/document/invoice/list",
      params
    );
  },
  exportInvoice(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/document/invoice/download",
      params,
      { responseType: "blob" }
    );
  },
  // Credit Notes
  getCreditNotesList(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/document/credit-note/list",
      params
    );
  },
  exportCreditNotes(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/document/credit-note/download",
      params,
      { responseType: "blob" }
    );
  },
  // Payment Confirmation
  getPaymentConfirmationList(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/document/payment-confirmation/list",
      params
    );
  },
  exportPaymentConfirmation(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/document/payment-confirmation/download",
      params,
      { responseType: "blob" }
    );
  },
  updateBillingStatus(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/document/billing/change-status",
      params
    );
  },
  updatePaymentStatus(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/document/payment/change-status",
      params
    );
  },
};
