/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-06-07 09:50:50
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-06-07 10:28:55
 * @FilePath: /settlement-frontend/src/core/api/merchantCreditNotes.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/merchant-credit-note/index",
      params
    );
  },
  getInfo(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/merchant-credit-note/show",
      params
    );
  },
  create(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/merchant-credit-note/create-by-upload",
      params
    );
  },
  save(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/merchant-credit-note/save",
      params
    );
  },
  verified(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/merchant-credit-note/verified",
      params
    );
  },
  confirmReturn(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/merchant-credit-note/confirm-return",
      params
    );
  },
  delete(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/merchant-credit-note/delete",
      params
    );
  },
  searchItem(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/merchant-credit-note/search-item",
      params
    );
  },
  upload() {
    return (
      Config.fulfillmentApiUrl + "/api/fulfillment/merchant-credit-note/upload"
    );
  },
};
