/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-11-04 15:27:17
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-05-17 10:09:37
 */
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { CommonModuleRouter } from "../interface/common";
import { CommonLanguageType } from "../type/common";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getSettlementDocumentsModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setSettlementDocumentsDetailName(language, route, result);
  return result;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
const setSettlementDocumentsDetailName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "/settlement-documents/";
  result.parentName = [language("common.documents")];
  if (route.path.indexOf("invoice") > -1) {
    strName = language("documents.invoice");
    listPath += "invoice";
  } else if (route.path.indexOf("report") > -1) {
    strName = language("documents.report");
    listPath += "report";
  } else if (route.path.indexOf("quick-inquiry") > -1) {
    strName = language("influencerAccount.quickInquiry");
    listPath += "quick-inquiry";
  } else if (route.path.indexOf("standard-operating-procedure") > -1) {
    strName = language("standardOperatingProcedure.standardOperatingProcedure");
    listPath += "standard-operating-procedure";
  } else if (route.path.indexOf("standard-script") > -1) {
    strName = language("standardScript.standardScript");
    listPath += "standard-script";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getSettlementDocumentsDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "documents-inspection-report-information":
      result.currentName = language("common.information");
      setSettlementDocumentsDetailName(language, route, result, router);
      break;
  }
  return result;
};
