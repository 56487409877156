/*
 * @Author: xiang.gao 57524479+GaoXiang0403@users.noreply.github.com
 * @Date: 2024-05-21 10:47:53
 * @LastEditors: xiang.gao 57524479+GaoXiang0403@users.noreply.github.com
 * @LastEditTime: 2024-05-21 13:40:20
 * @FilePath: /settlement-frontend/src/core/directive/type/serviceManagement.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export enum ServiceManagementStatus {
  Draft = 5,
  Activated = 10,
  Deactivated = 25,
}

export enum EntityServiceType {
  STV,
  SLF,
  SFI,
  SCO,
}
