/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-07-12 16:41:20
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-07-14 16:31:47
 * @FilePath: /settlement-frontend/src/core/directive/function/settlementCash.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { CommonModuleRouter } from "../interface/common";
import { BadgeStyle, CommonLanguageType } from "../type/common";
import { InternalCashPoolStatus } from "../type/settlementCash";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getSettlementCashModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setSettlementCashDetailName(language, route, result);
  return result;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
const setSettlementCashDetailName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "/settlement-cash/";
  result.parentName = [language("common.settlementCash")];
  if (route.path.includes("internal-cash-pool")) {
    strName = language("settlementCash.internalCashPool");
    listPath += "internal-cash-pool/";
  } else if (route.path.includes("internal-bank-balance")) {
    strName = language("settlementCash.internalBankBalance");
    listPath += "internal-bank-balance/";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getSettlementCashDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "documents-inspection-report-information":
      result.currentName = language("common.information");
      setSettlementCashDetailName(language, route, result, router);
      break;
  }
  return result;
};

export const getInternalCashPoolStatus = (
  status: number
): BadgeStyle | undefined => {
  const result = new Map([
    [InternalCashPoolStatus.Unpaid, BadgeStyle.LightDanger],
    [InternalCashPoolStatus.Paid, BadgeStyle.LightSuccess],
  ]);
  return result.get(status);
};
