/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-06-12 17:50:39
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-07-13 09:11:15
 * @FilePath: /settlement-frontend/src/core/api/debtor.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  searchDebtor(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/set-settlement-entity/option",
      params
    );
  },
};
