/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-07-21 16:00:53
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-09-26 09:39:54
 * @FilePath: /settlement-frontend/src/core/directive/function/settlementMasterData.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { CommonLanguageType } from "../type/common";
import { CommonModuleRouter } from "../interface/common";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getSettlementMasterDataModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setSettlementMasterDataDetailName(language, route, result);
  return result;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
const setSettlementMasterDataDetailName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "/settlement-master-data/";
  result.parentName = [language("common.settlementMasterData")];
  if (route.path.includes("settlement-entity")) {
    strName = language("settlementEntity.settlementEntity");
    listPath += "settlement-entity";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getSettlementMasterDataDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "settlement-master-data-settlement-entity-overview":
      result.currentName = language("common.overview");
      setSettlementMasterDataDetailName(language, route, result, router);
      break;
    case "settlement-master-data-settlement-entity-update":
      result.currentName = language("common.overview");
      setSettlementMasterDataDetailName(language, route, result, router);
      break;
  }
  return result;
};
