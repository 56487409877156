/*
 * @Author: xiang.gao 57524479+GaoXiang0403@users.noreply.github.com
 * @Date: 2024-05-24 16:10:56
 * @LastEditors: Json Weng dinglong.weng@travel-easy.com
 * @LastEditTime: 2024-06-03 03:55:08
 * @FilePath: /settlement-frontend/src/core/directive/type/kycVerification.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

export enum KycVerificationStatus {
  PendingVerification = 15,
  /**
   * @deprecated 这个状态已弃用
   */
  UnderVerification = 20,
  /**
   * @deprecated 这个状态已弃用
   */
  PartialVerified = 25,
  Confirming = 30,
  Verified = 35,
  VerificationFailed = 40,
  Temporarily = 50,
  Closed = 110,
}
