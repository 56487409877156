/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-07-12 16:41:20
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-07-12 16:43:23
 * @FilePath: /settlement-frontend/src/core/directive/function/settlementCash.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export enum InternalCashPoolStatus {
  Unpaid = 10,
  Paid = 20,
}
