/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-08-17 23:19:31
 * @LastEditors: GaoXiang0403 57524479+GaoXiang0403@users.noreply.github.com
 * @LastEditTime: 2023-06-23 18:35:45
 */
import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getList(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/merchant-invoice/index",
      params
    );
  },
  getInfo(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/merchant-invoice/show",
      params
    );
  },
  create(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/merchant-invoice/create-by-upload",
      params
    );
  },
  save(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/merchant-invoice/save",
      params
    );
  },
  checked(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/merchant-invoice/checked",
      params
    );
  },
  verified(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/merchant-invoice/verified",
      params
    );
  },
  confirmPayment(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/merchant-invoice/confirm-payment",
      params
    );
  },
  delete(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/fulfillment/merchant-invoice/delete",
      params
    );
  },
  searchItem(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/merchant-invoice/search-item",
      params
    );
  },
  upload() {
    return (
      Config.fulfillmentApiUrl + "/api/fulfillment/merchant-invoice/upload"
    );
  },
  exportXlsFiles(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/merchant-invoice/download-list",
      params,
      { responseType: "blob" }
    );
  },
  exportXlsFilesAutoMode() {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/merchant-invoice/download-merchant-list",
      null,
      { responseType: "blob" }
    );
  },
  exportPdfFiles(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/merchant-invoice/download-pdf-zip",
      params,
      { responseType: "blob" }
    );
  },
  confirmPaymentInvoices(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl +
        "/api/fulfillment/merchant-invoice/batch-confirm-payment",
      params
    );
  },
};
