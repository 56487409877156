/*
 * @Author: xiang.gao 57524479+GaoXiang0403@users.noreply.github.com
 * @Date: 2024-05-12 15:05:53
 * @LastEditors: xiang.gao 57524479+GaoXiang0403@users.noreply.github.com
 * @LastEditTime: 2024-05-21 10:48:11
 * @FilePath: /settlement-frontend/src/core/directive/type/entity.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export enum EntityStatus {
  Activated = 10,
  Pending = 15,
  Rejected = 20,
  Deactivated = 25,
  Locked = 30,
  Logout = 35,
  Archived = 100,
}

export enum EntityServiceStatus {
  PurchaseItems = 1,
  SalesItems,
  LuxuryFulfillment,
  TaxVatSettlement,
  Compliance,
}
