import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getQuickSettlementList(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/settlement/quickly/index",
      params
    );
  },
  getQuickSettlementInfo(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/settlement/quickly/show",
      params
    );
  },
  addQuickSettlement(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/settlement/quickly/store",
      params
    );
  },
  updateQuickSettlement(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/settlement/quickly/update",
      params,
    );
  },
  uploadQuickSettlementFile() {
    return Config.settlementApiUrl + "/api/settlement/quickly/upload";
  }
};
