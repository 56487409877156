/*
 * @Author: Json Weng dinglong.weng@travel-easy.com
 * @Date: 2024-07-03 11:08:08
 * @LastEditors: Json Weng dinglong.weng@travel-easy.com
 * @LastEditTime: 2024-07-03 14:15:35
 * @FilePath: \magicbean-saas-settlement\src\core\directive\type\refundProcessing.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export enum refundStatus {
  Created = 10,
  Approve = 20,
  Refunding = 30,
  Cancelled = 40,
  Failed = 50,
  Refunded = 60,
  Error = 70,
}
