/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-11-04 15:27:17
 * @LastEditors: Json Weng dinglong.weng@travel-easy.com
 * @LastEditTime: 2024-06-15 22:34:48
 */
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { CommonModuleRouter } from "../interface/common";
import { CommonLanguageType } from "../type/common";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getDocumentCenterModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setDocumentCenterDetailName(language, route, result);
  return result;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
const setDocumentCenterDetailName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "/document-center/";
  result.parentName = [language("common.documentCenter")];
  if (route.path.indexOf("billing") > -1) {
    strName = language("documentCenter.billing");
    listPath += "billing";
  }
  if (route.path.indexOf("billing-verification") > -1) {
    strName = language("documentCenter.billingVerification");
    listPath += "billing-verification";
  }
  if (route.path.indexOf("payment") > -1) {
    strName = language("documentCenter.payment");
    listPath += "payment";
  }
  if (route.path.indexOf("payment-verification") > -1) {
    strName = language("documentCenter.paymentVerification");
    listPath += "payment-verification";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getDocumentCenterDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "documents-inspection-report-information":
      result.currentName = language("common.information");
      setDocumentCenterDetailName(language, route, result, router);
      break;
  }
  return result;
};
