/*
 * @Author: xiang.gao 57524479+GaoXiang0403@users.noreply.github.com
 * @Date: 2024-05-12 15:08:44
 * @LastEditors: Json Weng dinglong.weng@travel-easy.com
 * @LastEditTime: 2024-06-11 22:30:40
 * @FilePath: /settlement-frontend/src/core/directive/function/entity.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { RouteLocationNormalizedLoaded, Router, useRoute } from "vue-router";
import { BadgeStyle, CommonLanguageType, NumberOrString } from "../type/common";
import { EntityServiceStatus, EntityStatus } from "../type/entity";
import { CommonModuleRouter } from "../interface/common";
import { SettlementEntityConfig } from "../interface/entity";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getSettlementEntityModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setSettlementEntityDetailName(language, route, result);
  return result;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
const setSettlementEntityDetailName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "/settlement-entity/";
  result.parentName = [language("settlementEntity.settlementEntity")];
  if (route.path.includes("retailer")) {
    strName = language("entity.retailer.title");
    listPath += "retailer";
  } else if (route.path.includes("purchaser")) {
    strName = language("entity.purchaser.title");
    listPath += "purchaser";
  } else if (route.path.includes("buyer")) {
    strName = language("entity.buyer.title");
    listPath += "buyer";
  } else if (route.path.includes("consumer")) {
    strName = language("entity.consumer.title");
    listPath += "consumer";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getSettlementEntityDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "settlement-entity-retailer-information":
    case "settlement-entity-purchaser-information":
    case "settlement-entity-buyer-information":
    case "settlement-entity-consumer-information":
      result.currentName = language("entity.retailer.information");
      setSettlementEntityDetailName(language, route, result, router);
      break;
    case "settlement-entity-retailer-attachment":
    case "settlement-entity-purchaser-attachment":
    case "settlement-entity-buyer-attachment":
    case "settlement-entity-consumer-attachment":
      result.currentName = language("entity.retailer.attachment");
      setSettlementEntityDetailName(language, route, result, router);
      break;
    case "settlement-entity-retailer-services":
    case "settlement-entity-purchaser-services":
    case "settlement-entity-buyer-services":
    case "settlement-entity-consumer-services":
      result.currentName = language("entity.retailer.services");
      setSettlementEntityDetailName(language, route, result, router);
      break;
  }
  return result;
};

/**
 * @description:
 * @param {NumberOrString} status
 * @param {CommonLanguageType} language
 * @return {*}
 */
export const getEntityStatus = (
  status: NumberOrString,
  language: CommonLanguageType
) => {
  let color = "",
    title = "";
  const currentStatus = Number(status) as EntityStatus;
  switch (currentStatus) {
    case EntityStatus.Activated:
      color = BadgeStyle.LightSuccess;
      title = language("entity.retailer.status.activated");
      break;
    case EntityStatus.Pending:
      color = BadgeStyle.LightPrimary;
      title = language("entity.retailer.status.pending");
      break;
    case EntityStatus.Deactivated:
      color = BadgeStyle.LightPrimary;
      title = language("entity.retailer.status.deactivated");
      break;
    case EntityStatus.Locked:
      color = BadgeStyle.LightDanger;
      title = language("entity.retailer.status.locked");
      break;
    case EntityStatus.Logout:
      color = BadgeStyle.Light;
      title = language("entity.retailer.status.logout");
      break;
    case EntityStatus.Archived:
      color = BadgeStyle.LightInfo;
      title = language("entity.retailer.status.archived");
      break;
    default:
      // eslint-disable-next-line no-case-declarations
      const n = currentStatus;
      console.log(n);
      break;
  }
  return {
    color,
    title,
  };
};

/**
 * @description:
 * @param {NumberOrString} status
 * @param {CommonLanguageType} language
 * @return {*}
 */
export const getEntityServiceStatus = (
  status: NumberOrString,
  language: CommonLanguageType
) => {
  let icon = "",
    title = "";
  const currentStatus = Number(status) as EntityServiceStatus;
  switch (currentStatus) {
    case EntityServiceStatus.PurchaseItems:
      icon = "media/icons/duotune/ecommerce/ecm001.svg";
      title = language("entity.retailer.serviceStatus.purchaseItems");
      break;
    case EntityServiceStatus.SalesItems:
      icon = "media/icons/duotune/ecommerce/ecm003.svg";
      title = language("entity.retailer.serviceStatus.salesItems");
      break;
    case EntityServiceStatus.LuxuryFulfillment:
      icon = "media/icons/duotune/ecommerce/ecm007.svg";
      title = language("entity.retailer.serviceStatus.luxuryFulfillment");
      break;
    case EntityServiceStatus.TaxVatSettlement:
      icon = "media/icons/duotune/finance/fin011.svg";
      title = language("entity.retailer.serviceStatus.taxVatSettlement");
      break;
    case EntityServiceStatus.Compliance:
      icon = "media/icons/duotune/general/gen070.svg";
      title = language("entity.retailer.serviceStatus.taxVatSettlement");
      break;
    default:
      // eslint-disable-next-line no-case-declarations
      const n = currentStatus;
      console.log(n);
      break;
  }
  return {
    icon,
    title,
  };
};

export const getSettlementEntityTypeParams = computed(() => {
  const route = useRoute();
  const { t } = useI18n();
  const routeName = route.name;
  const params: SettlementEntityConfig = {
    id: "",
    title: "",
    total: "",
    quantityItems: "",
    business_subtype: "",
    routerUrl: "",
  };
  switch (routeName) {
    case "settlement-entity-retailer":
    case "settlement-entity-retailer-information":
    case "settlement-entity-retailer-attachment":
    case "settlement-entity-retailer-services":
      params.id = "2";
      params.title = t("entity.retailer.title");
      params.business_subtype = "retailer";
      params.routerUrl = "/settlement-entity/retailer";
      params.total = t("entityRelationships.salesTotal");
      params.quantityItems = t("entityRelationships.quantityItemsSold");
      break;
    case "settlement-entity-purchaser":
    case "settlement-entity-purchaser-information":
    case "settlement-entity-purchaser-attachment":
    case "settlement-entity-purchaser-services":
      params.id = "3";
      params.title = t("entity.purchaser.title");
      params.business_subtype = "purchaser";
      params.routerUrl = "/settlement-entity/purchaser";
      params.total = t("entityRelationships.purchaseTotal");
      params.quantityItems = t("entityRelationships.quantityItemsPurchased");
      break;
    case "settlement-entity-buyer":
    case "settlement-entity-buyer-information":
    case "settlement-entity-buyer-attachment":
    case "settlement-entity-buyer-services":
      params.id = "4";
      params.title = t("entity.buyer.title");
      params.business_subtype = "natural_buyer";
      params.routerUrl = "/settlement-entity/buyer";
      params.total = t("entityRelationships.purchaseTotal");
      params.quantityItems = t("entityRelationships.quantityItemsPurchased");
      break;
    case "settlement-entity-consumer":
    case "settlement-entity-consumer-information":
    case "settlement-entity-consumer-attachment":
    case "settlement-entity-consumer-services":
      params.id = "5";
      params.title = t("entity.consumer.title");
      params.business_subtype = "consumer";
      params.routerUrl = "/settlement-entity/consumer";
      params.total = t("entityRelationships.purchaseTotal");
      params.quantityItems = t("entityRelationships.quantityItemsPurchased");
      break;
  }
  return params;
});
