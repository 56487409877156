import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-nowrap"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.amount !== void 0 && _ctx.amount !== null)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.currency == "EUR"
        ? _ctx.getDirectionCurrency +
          _ctx.$currency.currency(_ctx.amount, _ctx.precision, ",", ".", "") +
          " €"
        : _ctx.$currency.currency(
            _ctx.amount,
            _ctx.precision,
            ".",
            "",
            "￥ " + _ctx.getDirectionCurrency
          )), 1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.currency == "EUR" ? "-- €" : "￥ --"), 1))
}