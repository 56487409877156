/*
 * @Author: xiang.gao 57524479+GaoXiang0403@users.noreply.github.com
 * @Date: 2024-05-17 15:48:38
 * @LastEditors: xiang.gao 57524479+GaoXiang0403@users.noreply.github.com
 * @LastEditTime: 2024-05-22 15:50:38
 * @FilePath: /settlement-frontend/src/core/directive/function/serviceManagement.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { computed } from "vue";
import { RouteLocationNormalizedLoaded, Router, useRoute } from "vue-router";
import { BadgeStyle, CommonLanguageType, NumberOrString } from "../type/common";
import { CommonModuleRouter } from "../interface/common";
import { ServiceManagementStatus } from "../type/serviceManagement";
import { ServiceManagementConfig } from "../interface/serviceManagement";
import { useI18n } from "vue-i18n";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getServiceManagementModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setServiceManagementDetailName(language, route, result);
  return result;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
const setServiceManagementDetailName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "/service-management/";
  result.parentName = [language("common.serviceManagement")];
  if (route.path.includes("financial-tax-service")) {
    strName = language("serviceManagement.financialTaxServices.title");
    listPath += "financial-tax-service";
  } else if (route.path.includes("luxury-fulfillment-service")) {
    strName = language("serviceManagement.luxuryFulfillmentService.title");
    listPath += "luxury-fulfillment-service";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getServiceManagementDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "service-management-financial-tax-service-overview":
    case "service-management-financial-tax-service-overview-add":
    case "service-management-financial-tax-service-overview-edit":
    case "service-management-luxury-fulfillment-service-overview":
    case "service-management-luxury-fulfillment-service-overview-add":
    case "service-management-luxury-fulfillment-service-overview-edit":
      result.currentName = language("serviceManagement.overview.title");
      setServiceManagementDetailName(language, route, result, router);
      break;
  }
  return result;
};

/**
 * @description:
 * @param {NumberOrString} status
 * @param {CommonLanguageType} language
 * @return {*}
 */
export const getServiceManagementStatus = (
  status: NumberOrString,
  language: CommonLanguageType
) => {
  let color = "",
    title = "";
  const currentStatus = Number(status) as ServiceManagementStatus;
  switch (currentStatus) {
    case ServiceManagementStatus.Activated:
      color = BadgeStyle.LightSuccess;
      title = language("serviceManagement.status.activated");
      break;
    case ServiceManagementStatus.Deactivated:
      color = BadgeStyle.LightDanger;
      title = language("serviceManagement.status.deactivated");
      break;
    case ServiceManagementStatus.Draft:
      color = BadgeStyle.Light;
      title = language("serviceManagement.status.draft");
      break;
    default:
      // eslint-disable-next-line no-case-declarations
      const n = currentStatus;
      console.log(n);
      break;
  }
  return {
    color,
    title,
  };
};

export const getServiceManagementTypeParams = computed(() => {
  const route = useRoute();
  const { t } = useI18n();
  const routeName = route.name;
  const params: ServiceManagementConfig = {
    type: "",
    routerUrl: "",
  };
  switch (routeName) {
    case "service-management-financial-tax-service":
    case "service-management-financial-tax-service-overview":
    case "service-management-financial-tax-service-overview-add":
    case "service-management-financial-tax-service-overview-edit":
      params.type = "STV";
      params.routerUrl = "/service-management/financial-tax-service";
      break;
    case "service-management-luxury-fulfillment-service":
    case "service-management-luxury-fulfillment-service-overview":
    case "service-management-luxury-fulfillment-service-overview-add":
    case "service-management-luxury-fulfillment-service-overview-edit":
      params.type = "STV";
      params.routerUrl = "/service-management/luxury-fulfillment-service";
      break;
  }
  return params;
});
