import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getCompanyList(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/company/index",
      params
    );
  },
  addCompany(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/company/store",
      params
    );
  },
  updateCompany(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/company/update",
      params
    );
  },
  deleteCompany(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/company/delete",
      params
    );
  },
  getCompanyInfo(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/company/show",
      params
    );
  },

  getFiscalHistoryList(params?) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/fiscal-history/index",
      params
    );
  },
  addFiscalHistory(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/fiscal-history/store",
      params
    );
  },
  updateFiscalHistory(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/fiscal-history/update",
      params
    );
  },
  deleteFiscalHistory(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/fiscal-history/delete",
      params
    );
  },
  getFiscalHistoryInfo(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/fiscal-history/show",
      params
    );
  },

  getMerchantPimData(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/company/pim/show",
      params
    );
  },
  updateMerchantPimData(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/merchant/company/pim/update",
      params
    );
  },
  getSalesChannelsData(params) {
    return ApiService.post(
      Config.merchantApiUrl + "/api/data-source/channel",
      params
    );
  },
};
