/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-09-30 10:50:50
 * @LastEditors: xiang.gao 57524479+GaoXiang0403@users.noreply.github.com
 * @LastEditTime: 2024-03-12 14:38:35
 */
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { CommonModuleRouter } from "../interface/common";
import { CommonLanguageType } from "../type/common";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getMerchantInvoiceModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setMerchantInvoiceDetailName(language, route, result);
  return result;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
const setMerchantInvoiceDetailName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "/merchant-invoice/";
  result.parentName = [];
  if (route.path.indexOf("merchant-invoice-finance") > -1) {
    strName = language("common.merchantInvoiceFinance");
    listPath += "merchant-credit-notes";
  }
  else if (route.path.indexOf("merchant-invoice") > -1) {
    strName = language("common.merchantInvoice");
    listPath += "merchant-invoice";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getMerchantInvoiceDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "documents-inspection-report-information":
      result.currentName = language("common.information");
      setMerchantInvoiceDetailName(language, route, result, router);
      break;
  }
  return result;
};

export const getVatTypeOptions = (language: CommonLanguageType) => {
  return [
    {
      label: language("common.net"),
      value: "net",
    },
    {
      label: language("common.gross"),
      value: "gross",
    },
  ];
};

export const getDataVersion = (route: RouteLocationNormalizedLoaded) => {
  if( route.path.indexOf("-finance") > -1 ){
    return 1;
  } else {
    return 2;
  }
}
