/*
 * @Author: xiang.gao 57524479+GaoXiang0403@users.noreply.github.com
 * @Date: 2024-04-26 09:13:07
 * @LastEditors: xiang.gao 57524479+GaoXiang0403@users.noreply.github.com
 * @LastEditTime: 2024-04-30 18:45:01
 * @FilePath: /settlement-frontend/src/core/directive/function/entityRelationships.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { CommonModuleRouter } from "../interface/common";
import { CommonLanguageType } from "../type/common";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getEntityRelationshipsModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setEntityRelationshipsDetailName(language, route, result);
  return result;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
const setEntityRelationshipsDetailName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "";
  if (route.path.indexOf("-ssp") > -1) {
    listPath += "/entity-relationships-ssp";
    result.parentName = [language("common.entityRelationshipsSSP")];
  } else if (route.path.indexOf("-retailer") > -1) {
    listPath += "/entity-relationships-retailer";
    result.parentName = [language("common.entityRelationshipsRetailer")];
  }
  if (route.path.indexOf("supplier") > -1) {
    strName = language("common.supplier");
    listPath += "supplier";
  } else if (route.path.indexOf("buyer") > -1) {
    strName = language("common.buyer");
    listPath += "buyer";
  } else if (route.path.indexOf("clientele") > -1) {
    strName = language("common.clientele");
    listPath += "clientele";
  } else if (route.path.indexOf("payee") > -1) {
    strName = language("common.payee");
    listPath += "payee";
  } else if (route.path.indexOf("rebate") > -1) {
    strName = language("common.clienteleRebate");
    listPath += "rebate";
  } else if (route.path.indexOf("exporter") > -1) {
    strName = language("common.exporter");
    listPath += "exporter";
  } else if (route.path.indexOf("tax-agent") > -1) {
    strName = language("common.taxAgent");
    listPath += "tax-agent";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getEntityRelationshipsDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "quick-settlement-information":
      result.currentName = language("common.information");
      setEntityRelationshipsDetailName(language, route, result, router);
      break;
  }
  return result;
};
