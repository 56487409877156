/*
 * @Author: xiang.gao 57524479+GaoXiang0403@users.noreply.github.com
 * @Date: 2024-05-21 13:12:57
 * @LastEditors: Json Weng dinglong.weng@travel-easy.com
 * @LastEditTime: 2024-07-03 14:26:42
 * @FilePath: /settlement-frontend/src/core/directive/function/kfcVerification.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { ComputedRef, computed } from "vue";
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { BadgeStyle, CommonLanguageType, NumberOrString } from "../type/common";
import { CommonModuleRouter } from "../interface/common";
import { refundStatus } from "../type/refundProcessing";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getRefundProcessingModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setRefundProcessingDetailName(language, route, result);
  return result;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
const setRefundProcessingDetailName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  const strName = language("refundProcessing.refundProcessing");
  const listPath = "/refund-processing";
  result.parentName = [""];
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getRefundProcessingDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "refund-processing-information":
      result.currentName = language(
        "refundProcessing.information.refundOverview"
      );
      setRefundProcessingDetailName(language, route, result, router);
      break;
  }
  return result;
};

/**
 * @description: 退款状态badge
 * @param {NumberOrString} status
 * @param {CommonLanguageType} language
 * @return {*}
 */
export const getRefundStatus = (
  status: NumberOrString,
  language: CommonLanguageType
) => {
  let color = "",
    title = "";
  const currentStatus = status as refundStatus;
  switch (currentStatus) {
    case refundStatus.Created:
      color = BadgeStyle.LightPrimary;
      title = language("refundProcessing.status.created");
      break;
    case refundStatus.Approve:
      color = BadgeStyle.LightWarning;
      title = language("refundProcessing.status.approve");
      break;
    case refundStatus.Refunding:
      color = BadgeStyle.LightPrimary;
      title = language("refundProcessing.status.pending");
      break;
    case refundStatus.Cancelled:
      color = BadgeStyle.Light;
      title = language("refundProcessing.status.cancelled");
      break;
    case refundStatus.Failed:
      color = BadgeStyle.LightDanger;
      title = language("refundProcessing.status.failed");
      break;
    case refundStatus.Refunded:
      color = BadgeStyle.LightSuccess;
      title = language("refundProcessing.status.refunded");
      break;
    case refundStatus.Error:
      color = BadgeStyle.LightDanger;
      title = language("refundProcessing.status.error");
      break;
    default:
      // eslint-disable-next-line no-case-declarations
      const n: never = currentStatus;
      console.log(n);
      break;
  }
  return {
    color,
    title,
  };
};

export const useComputedFn = <T, U extends unknown[]>(
  fn: (...args: U) => T
): ((...args: U) => ComputedRef<T>) => {
  const cache: Map<string, ComputedRef<T>> = new Map();
  return (...args: U): ComputedRef<T> => {
    const cacheKey = JSON.stringify(args);
    if (cache.has(cacheKey)) return cache.get(cacheKey) as ComputedRef<T>;
    const result = computed(() => fn(...args));
    cache.set(cacheKey, result);
    return result;
  };
};
