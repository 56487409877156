import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_config_provider = _resolveComponent("el-config-provider")!
  const _component_FsLightbox = _resolveComponent("FsLightbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_config_provider, { locale: _ctx.locale }, {
      default: _withCtx(() => [
        _createVNode(_component_router_view, { onPreviewImages: _ctx.previewImages }, null, 8, ["onPreviewImages"])
      ]),
      _: 1
    }, 8, ["locale"]),
    _createVNode(_component_FsLightbox, {
      type: "image",
      toggler: _ctx.options.toggler,
      sources: _ctx.options.sources,
      slide: _ctx.options.sourcesSlide
    }, null, 8, ["toggler", "sources", "slide"])
  ], 64))
}