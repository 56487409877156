
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "common-currency",
  props: {
    currency: {
      type: String,
      default: "",
    },
    direction: {
      type: Number,
      default: 0,
    },
    amount: {
      type: String,
      default: "",
    },
    precision: {
      type: Number,
      default: 2,
    },
  },
  setup(props) {
    const getDirectionCurrency = computed(() => {
      return props.direction === 1 ? "-" : "";
    });
    return {
      getDirectionCurrency,
    };
  },
});
