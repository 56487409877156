/*
 * @Author: xiang.gao 57524479+GaoXiang0403@users.noreply.github.com
 * @Date: 2024-05-29 14:13:18
 * @LastEditors: xiang.gao 57524479+GaoXiang0403@users.noreply.github.com
 * @LastEditTime: 2024-05-29 15:56:21
 * @FilePath: /settlement-frontend/src/core/directive/interface/kycConfirmation.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { RouteLocationNormalizedLoaded, Router, useRoute } from "vue-router";
import { BadgeStyle, CommonLanguageType, NumberOrString } from "../type/common";
import { CommonModuleRouter } from "../interface/common";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { KycConfirmationConfig } from "../interface/kycConfirmation";
import {
  KycConfirmationBlockStatus,
  KycConfirmationStatus,
} from "../type/kycConfirmation";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getKycConfirmationModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setKycConfirmationDetailName(language, route, result);
  return result;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
const setKycConfirmationDetailName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "/kyc-confirmation/";
  result.parentName = [language("common.kycConfirmation")];
  if (route.path.includes("buyer")) {
    strName = language("kycConfirmation.buyer.title");
    listPath += "buyer";
  } else if (route.path.includes("consumer")) {
    strName = language("kycConfirmation.consumer.title");
    listPath += "consumer";
  } else if (route.path.includes("purchaser")) {
    strName = language("kycConfirmation.purchaser.title");
    listPath += "purchaser";
  } else if (route.path.includes("retailer")) {
    strName = language("kycConfirmation.retailer.title");
    listPath += "retailer";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getKycConfirmationDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "kyc-confirmation-buyer-information":
      result.currentName = language("kycConfirmation.buyer.overviewTitle");
      setKycConfirmationDetailName(language, route, result, router);
      break;
    case "kyc-confirmation-consumer-information":
      result.currentName = language("kycConfirmation.consumer.overviewTitle");
      setKycConfirmationDetailName(language, route, result, router);
      break;
    case "kyc-confirmation-purchaser-information":
      result.currentName = language("kycConfirmation.purchaser.overviewTitle");
      setKycConfirmationDetailName(language, route, result, router);
      break;
    case "kyc-confirmation-retailer-information":
      result.currentName = language("kycConfirmation.retailer.overviewTitle");
      setKycConfirmationDetailName(language, route, result, router);
      break;
  }
  return result;
};

/**
 * @description: KYC确认状态badge
 * @param {NumberOrString} status
 * @param {CommonLanguageType} language
 * @return {*}
 */
export const getKycConfirmationStatus = (
  status: NumberOrString,
  language: CommonLanguageType
) => {
  let color = "",
    title = "";
  const currentStatus = Number(status) as KycConfirmationStatus;
  switch (currentStatus) {
    case KycConfirmationStatus.PendingApprove:
      color = BadgeStyle.LightWarning;
      title = language("kycConfirmation.status.pendingApprove");
      break;
    case KycConfirmationStatus.Approved:
      color = BadgeStyle.LightSuccess;
      title = language("kycConfirmation.status.approved");
      break;
    case KycConfirmationStatus.Disapproved:
      color = BadgeStyle.LightDanger;
      title = language("kycConfirmation.status.disapproved");
      break;
    default:
      // eslint-disable-next-line no-case-declarations
      const n: never = currentStatus;
      console.log(n);
      break;
  }
  return {
    color,
    title,
  };
};

/**
 * @description: KYC详情页面分块确认状态badge
 * @param {NumberOrString} status
 * @param {CommonLanguageType} language
 * @return {*}
 */
export const getKycConfirmationBlockStatus = (
  status: any,
  language: CommonLanguageType
) => {
  let color = "",
    title = "";
  const currentStatus = status as string as KycConfirmationBlockStatus;
  switch (currentStatus) {
    case KycConfirmationBlockStatus.Pending:
      color = BadgeStyle.LightWarning;
      title = language("kycConfirmation.blockStatus.pending");
      break;
    case KycConfirmationBlockStatus.Approved:
      color = BadgeStyle.LightSuccess;
      title = language("kycConfirmation.blockStatus.approved");
      break;
    case KycConfirmationBlockStatus.Rejected:
      color = BadgeStyle.LightDanger;
      title = language("kycConfirmation.blockStatus.rejected");
      break;
    default:
      // eslint-disable-next-line no-case-declarations
      const n: never = currentStatus;
      console.log(n);
      break;
  }
  return {
    color,
    title,
  };
};

export const getKycConfirmationTypeParams = computed(() => {
  const route = useRoute();
  //const { t } = useI18n();
  const routeName = route.name;
  const params: KycConfirmationConfig = {
    entityType: "",
    routerUrl: "",
    apiSubType: "",
  };
  switch (routeName) {
    // 买手
    case "kyc-confirmation-buyer":
    case "kyc-confirmation-buyer-information":
      params.entityType = "natural"; // 自然人
      params.apiSubType = "natural_buyer";
      params.routerUrl = "/kyc-confirmation/buyer";
      break;

    // 消费者
    case "kyc-confirmation-consumer":
    case "kyc-confirmation-consumer-information":
      params.entityType = "natural"; // 自然人
      params.apiSubType = "consumer";
      params.routerUrl = "/kyc-confirmation/consumer";
      break;

    // 采购商
    case "kyc-confirmation-purchaser":
    case "kyc-confirmation-purchaser-information":
      params.entityType = "juridical"; // 法人
      params.apiSubType = "purchaser";
      params.routerUrl = "/kyc-confirmation/purchaser";
      break;

    // 零售商
    case "kyc-confirmation-retailer":
    case "kyc-confirmation-retailer-information":
      params.entityType = "juridical"; // 法人
      params.apiSubType = "retailer";
      params.routerUrl = "/kyc-confirmation/retailer";
      break;
  }
  return params;
});
