import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getInvoiceList(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/invoice/list",
      params
    );
  },
  getDataSourceInvoiceType() {
    return ApiService.post(
      Config.settlementApiUrl + "/api/data-source/invoice/type"
    );
  },
  getDataSourceInvoiceMerchant() {
    return ApiService.post(
      Config.settlementApiUrl + "/api/data-source/invoice/merchant"
    );
  },
  getInvoiceDownloadPDF(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/invoice/download-pdf",
      params,
      { responseType: "blob" }
    );
  },
  getInvoiceDownloadAllPDF(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/invoice/download-pdf-zip",
      params,
      { responseType: "blob" }
    );
  },

  getInvoiceReportList(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/invoice/report/list",
      params
    );
  },
  getDataSourceReportType() {
    return ApiService.post(
      Config.settlementApiUrl + "/api/data-source/report/type"
    );
  },
  getInvoiceDownloadCSV(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/invoice/report/download-csv",
      params,
      { responseType: "blob" }
    );
  },
  getInvoiceDownloadAllCSV(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/invoice/report/download-csv-zip",
      params,
      { responseType: "blob" }
    );
  },
  exportDoubleSaleCommercialInvoice(params) {
    // Config.fulfillmentApiUrl + "/api/fulfillment/sales-order/export-double-sales-commercial-invoice"
    return ApiService.post(
      Config.settlementApiUrl + "/api/invoice/sales-order/export-double-sales-commercial-invoice",
      params,
      { responseType: "blob" }
    );
  },
  exportDoubleSaleCreditNote(params) {
    // Config.fulfillmentApiUrl + "/api/fulfillment/sales-order/export-double-sales-credit-note"
    return ApiService.post(
      Config.settlementApiUrl + "/api/invoice/sales-order/export-double-sales-credit-note",
      params,
      { responseType: "blob" }
    );
  }
};
