/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-07-12 16:21:01
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-07-14 13:51:17
 * @FilePath: /settlement-frontend/src/core/api/cash.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  // Cash
  getCashList(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/transaction/cash/index",
      params
    );
  },
  cashRefundPay(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/transaction/cash/refund-pay",
      params
    );
  },
  cashPayment(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/transaction/cash/payment",
      params
    );
  },
  exportCash(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/transaction/cash/export",
      params,
      { responseType: "blob" }
    );
  },
  // Internal Bank
  getInternalBankList(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/transaction/internal-bank/index",
      params
    );
  },
  internalBankAddPrepayment(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/transaction/internal-bank/add-prepayment",
      params
    );
  },
  transferInternalBank(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/transaction/internal-bank/transfer",
      params
    );
  },
  exportInternalBank(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/transaction/cash/export",
      params,
      { responseType: "blob" }
    );
  },
};
