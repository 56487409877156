
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  ref,
} from "vue";

import FsLightbox from "fslightbox-vue/v3";
import store from "./store";
import zhCN from "element-plus/es/locale/lang/zh-cn";
import enUS from "element-plus/es/locale/lang/en";
import deDE from "element-plus/es/locale/lang/de";

export default defineComponent({
  name: "app",
  components: {
    FsLightbox,
  },
  setup() {
    const currentInstance: any = getCurrentInstance();

    const options = ref({
      toggler: false,
      sources: [] as any[],
      sourcesIndex: -1,
      sourcesSlide: 1,
    });

    const locale = computed(() => {
      const lang = store.getters.currentUser?.language;
      switch (lang) {
        case "zh_CN":
          return zhCN;
        case "en_US":
          return enUS;
        case "de_DE":
          return deDE;
        default:
          return enUS;
      }
    });

    const previewImages = () => {
      currentInstance.proxy.emitter.on("preview-images", function (obj) {
        options.value.sources = obj.sources;
        // options.value.sourcesIndex = obj.sourcesIndex;
        options.value.sourcesSlide = obj.sourcesSlide;
        options.value.toggler = !options.value.toggler;
      });
    };

    const offEmitter = () => {
      currentInstance.proxy.emitter.off();
    };

    onMounted(() => {
      previewImages();
    });

    onUnmounted(() => {
      offEmitter();
    });

    return {
      options,
      locale,
      previewImages,
    };
  },
});
