import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";
import store from "@/store";

export default {
  getMenuConfig() {
    return ApiService.post(Config.baseApiUrl + "/api/config/menu", {});
  },

  getCountryData() {
    return ApiService.post(
      Config.baseApiUrl + "/api/data-source/global-data/country",
      {}
    );
  },

  getPhoneCodeData(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/data-source/global-data/country-phone-code",
      params
    );
  },

  getTaggingData(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/data-source/tagging/tag",
      params
    );
  },

  getTaggingGroupData(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/data-source/tagging/group",
      params
    );
  },

  getBrandSourceData(params) {
    return ApiService.post(Config.pimApiUrl + "/api/data-source/brand", params);
  },

  getCategorySourceData(params) {
    return ApiService.post(
      Config.pimApiUrl + "/api/data-source/category",
      params
    );
  },

  getMerchantSourceData(params) {
    // const idParams =
    //   store.getters.currentUser.user_managed_accounts.merchant.length > 0
    //     ? {
    //         id: store.getters.currentUser.user_managed_accounts.merchant,
    //       }
    //     : {};
    const idParams =
      store.getters.currentUser.user_type === 1
        ? {
            id: store.getters.currentUser.user_managed_accounts.merchant,
          }
        : {};
    return ApiService.post(
      Config.merchantApiUrl + "/api/data-source/merchant",
      {
        ...idParams,
        ...params,
      }
    );
  },

  getCustomerSourceData(params) {
    // const idParams =
    //   store.getters.currentUser.user_managed_accounts.customer.length > 0
    //     ? {
    //         id: store.getters.currentUser.user_managed_accounts.customer,
    //       }
    //     : {};
    const idParams =
      store.getters.currentUser.user_type === 1
        ? {
            id: store.getters.currentUser.user_managed_accounts.customer,
          }
        : {};
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/basic/data-source/customers",
      {
        ...idParams,
        ...params,
      }
    );
  },

  getUserSourceData(params) {
    return ApiService.post(Config.baseApiUrl + "/api/data-source/user", {
      status: 10,
      ...params,
    });
  },

  getAccountSourceData(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/data-source/account/name",
      params
    );
  },

  getInfluencerGroupSourceData(params) {
    return ApiService.post(
      Config.distributionApiUrl + "/api/data-source/account/influencer-group",
      params
    );
  },

  getFile(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/common/file/get",
      params,
      { responseType: "blob" }
    );
  },

  getFulfillmentFile(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/common/file/get",
      params,
      { responseType: "blob" }
    );
  },

  getWarehouseData(params) {
    return ApiService.post(
      Config.fulfillmentApiUrl + "/api/basic/data-source/warehouse",
      {
        active: 1,
        ...params,
      }
    );
  },

  getUserOperatingEntity(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/authorize/me/operating-entity",
      params
    );
  },

  getSsoApps(params) {
    return ApiService.post(Config.baseApiUrl + "/api/sso/apps", params);
  },

  getSsoAppUrl(url, params) {
    return ApiService.post(url, params);
  },
};
